import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const keepAliveList = ["HomeView"];
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: keepAliveList
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      }), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "footer-beian"
      }, [_createTextVNode(" 冀ICP备2023016871号 "), _createElementVNode("a", {
        href: "https://beian.miit.gov.cn/",
        target: "_blank",
        rel: "noopener noreferrer"
      }, " 工信部备案查询 ")], -1))], 64);
    };
  }
};